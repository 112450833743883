import Button from "react-bootstrap/Button";
import { CreateBacktestForm, FactorModelType } from "../../../api/schema";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useModelQuery } from "../../../hooks/useModel";
import { isLowestScore } from "../../../utils/utils";


const BacktestSettingsForm = ({
    handleClose,
    onSubmit
}: {
    handleClose?: () => void;
    onSubmit?: (backtest: CreateBacktestForm) => Promise<void>;
}) => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const navigate = useNavigate();
    const today = new Date();
    const onEditPres = (path: string) => {
        navigate(path);
    };

        if (modelQuery.data) {
            const model = modelQuery.data;
            return (
                <>
                    <Row><h3>Strategy and backtest parameters</h3></Row>
                    <Row>
                        <div style={{
                            textAlign: "left",
                        }}>
                            <div>Built on model: <span className="metrics">{Object.keys(model.factor|| {})[0]}</span> </div>
                            <div>The portfolio consist of the top <span className="metrics">{model.backtest?.numberOfStocks}</span> assets ranked by the model.</div>
                            {/* <div><span className="metrics">{model.backtest?.longOnly ? "Use" : "Don't use"}</span> the bottom 10 assets from the rank as a portfolio hedge.</div> */}
                            <div>The strategy is using leverage <span className="metrics">{model.backtest?.leverage}</span> for the invested capital.</div>
                            <div><span className="metrics">{model.backtest?.longsShare}</span> of the capital is invested uniformly as long positions in the stocks with the {isLowestScore(model.backtest?.ascending, model.backtest?.portfolioConstruction) ? "lowest" : "highest"} score.</div>
                            <div><span className="metrics">{model.backtest?.shortsShare}</span> of the capital is invested uniformly as short positions in the stocks with the {isLowestScore(model.backtest?.ascending, model.backtest?.portfolioConstruction) ? "highest" : "lowest"} score.</div>
                            <div><span className="metrics">0%</span> of the capital is left as cash reserve.</div>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <h3>Backtesting</h3>
                    </Row>
                    <Row>
                    <div style={{
                            textAlign: "left",
                        }}>
                            <div>Multi-period walk-forward out of sample method. This method provides greatest integrity and predicatbility results.</div>
                            <div>Performance is calculated with end-of-day closing prices.</div>
                            <div>Backtests calculates 0 fees per trade and the results are calculated from the close-close prices.</div>
                            <div>Dividends and yields are included here in cumulative performance as if they were continuously re-invested. This differs from the Edit Model section where yields are not reinvested.</div>
                            <div>The backtest period begins at: <span className="metrics">{model.backtest?.backtestPeriod}</span> and ends at <span className="metrics">{`${today.getFullYear()}-${today.getMonth().toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`}</span></div>
                            <div>Capital of <span className="metrics">${model.backtest?.startCash}</span> is  initially invested into the strategy.</div>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <Col md={6}>
                            <Button 
                                style={{
                                    width: "100%"
                                }}
                                onClick={() => onEditPres(`/model/${model.modelId}`)}>
                                Edit Model
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Button
                                style={{
                                    width: "100%"
                                
                                }}
                                onClick={() => onEditPres(`/strategy/${model.modelId}`)}>
                                Edit Strategy
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }

        return (
            <>
                <Row><h3>Strategy and backtest parameters</h3></Row>
            </>
        )
}

export default BacktestSettingsForm;