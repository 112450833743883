import { on } from "events";
import { useEffect, useRef, useState } from "react";
import { CloseButton, Col, Form, InputGroup, Overlay, Popover, Row } from "react-bootstrap"
import Calendar from "react-calendar";
import { BsCalendar2WeekFill } from "react-icons/bs"

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


const DateRangeInput = ({
    originalValue,
    onSelectedDateRangeChange
} : {
    originalValue: [string, string] | undefined;
    onSelectedDateRangeChange: (dateRange: [string, string] | undefined) => void;
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [shownText, setShownText] = useState("");
    const [selectedDateRange, setSelectedDateRange] = useState<Value>(new Date());
    const target = useRef(null);
    const onInputChange = (e: any) => {
        setShownText(e.target.value);
    };
    const onFocusOut = (e: any) => {
        const setFallbackText = () => {
            if (Array.isArray(selectedDateRange)) {
                const fromDate = selectedDateRange[0] as Date;
                const toDate = selectedDateRange[1] as Date;
                setShownText(`${fromDate.getMonth() + 1}/${fromDate.getFullYear()}-${toDate.getMonth() + 1}/${toDate.getFullYear()}`);
            } else {
                setShownText("");
            }
        };
        const text = e.target.value;
        if (!text) {
            setShownText("");
            return;
        }
        const [from, to] = text.split("-");
        if (!from || !to) {
            setFallbackText();
            return;
        }

        const [fromMonthText, fromYearText] = from.split("/");
        const [toMonthText, toYearText] = to.split("/");

        if ((!fromMonthText || !fromYearText || !toMonthText || !toYearText)) {
            setFallbackText();
            return;
        }

        const fromMonth = parseInt(fromMonthText);
        const fromYear = parseInt(fromYearText);
        const toMonth = parseInt(toMonthText);
        const toYear = parseInt(toYearText);

        if ((isNaN(fromMonth) || isNaN(fromYear) || isNaN(toMonth) || isNaN(toYear)) || (fromMonth < 0 || fromMonth > 11 || toMonth < 0 || toMonth > 11)) {
            setFallbackText();
            return;
        }

        const fromDate = new Date(fromYear, fromMonth - 1);
        const toDate = new Date(toYear, toMonth - 1);

        setSelectedDateRange([fromDate, toDate]);

    };
    const onCalendarSelect = (date: Value) => {
        if (Array.isArray(date)) {
            const fromDate = date[0] as Date;
            const toDate = date[1] as Date;
            setSelectedDateRange(date);
            
            setShownText(`${fromDate.getMonth() + 1}/${fromDate.getFullYear()}-${toDate.getMonth() + 1}/${toDate.getFullYear()}`);
            setShowCalendar(false);
        }
    };

    useEffect(() => {
        if (Array.isArray(selectedDateRange)) {
            const fromDate = selectedDateRange[0] as Date;
            const toDate = selectedDateRange[1] as Date;
            const fromDateText = `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1).toString().padStart(2, "0")}-01`;
            const toDateText = `${toDate.getFullYear()}-${(toDate.getMonth() + 1).toString().padStart(2, "0")}-01`;

            if (fromDateText !== originalValue?.[0] || toDateText !== originalValue?.[1]) {
                onSelectedDateRangeChange([fromDateText, toDateText]);
            }
        } else {
            if (!selectedDateRange) {
                onSelectedDateRangeChange(undefined);
            }
        }
    }, [selectedDateRange, onSelectedDateRangeChange, originalValue]);

  return (
    <>
        <InputGroup>
            <Form.Control type="text" value={shownText} onBlur={onFocusOut} onChange={onInputChange}  placeholder="MM/YYYY-MM/YYYY"/>
            <InputGroup.Text>
                <div ref={target} onClick={() => setShowCalendar(!showCalendar)} style={{
                    cursor: "pointer"
                }}>
                    <BsCalendar2WeekFill />
                </div>
            </InputGroup.Text>
        </InputGroup>

        <Overlay target={target.current} show={showCalendar} placement="left">
            {(props) => (
                <Popover id="popover-basic" {...props} >
                    <Popover.Header><Row>
                        <Col sm={10}>
                            Select Date Range
                        </Col>
                        <Col sm={2} >
                            <CloseButton onClick={() => setShowCalendar(false)} />
                        </Col>
                        </Row></Popover.Header>
                    <Popover.Body>
                        <Calendar
                            maxDetail="year"
                            maxDate={new Date()}
                            selectRange={true}
                            onChange={onCalendarSelect}
                            value={selectedDateRange}
                        />
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>
    </>
  )
}

export default DateRangeInput