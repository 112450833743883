import { Row, Table } from "react-bootstrap";
import { BacktestReportItem, DrawdownType, FactorModelType, drawdownSchema } from "../../../api/schema";
import { padNumber, toFixed } from "../../../utils/utils";
import { useModelQuery, useModelReportQuery } from "../../../hooks/useModel";
import { useParams } from "react-router-dom";

export const PerformanceDetails = () => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const report = useModelReportQuery(model?.userId, model?.modelId);
    const percentageFields = [
      "Final-Equity",
      "CAGR",
      "Volatility",
      "Max-Drawdown",
      "Win-Rate",
      "VaR95",
      "CVaR95",  
    ];
    const labelMap = {
        "Final-Equity": "Total Return",
        "CAGR": "Annual Returns (CAGR)",
        "Volatility": "Annual Volatility",
        "Sharpe": "Sharpe Ratio",
        "Max-Drawdown": "Max Drawdown",
        "Average-Drawdown": "Return over Max Drawdown",
        "Alpha-Universe": "Alpha (Universe)",
        "Beta-Universe": "Beta (Universe)",
        "Alpha-SP500": "Alpha (SP500)",
        "Beta-SP500": "Beta (SP500)",
        "Sterling": "Sterling Ratio",
        "Sortino": "Sortino Ratio",
        "Calmar": "Calmar Ratio",
        "Win-Rate": "Win Rate",
        "VaR95": "Value at Risk (95%)",
        "CVaR95": "Conditional Value at Risk (95%)",
        "Skewness": "Skewness",
        "Kurtosis": "Kurtosis"
    };

    if (report.data) {
        return (
            <Row>
                <h3>Performance Details</h3>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {/* {
                                ["Metrics", "Strategy", "SP100", "SP500", "LongOnly", "ShortOnly", "LongTheShort"].map((option) => (
                                    <th key={option}>{columnsMap[option as keyof typeof columnsMap]}</th>
                                ))
                            } */}
                            <th rowSpan={2}>Metrics</th>
                            <th rowSpan={2}>Strategy</th>
                            <th rowSpan={2}>Universe (S&P 100)</th>
                            <th rowSpan={2}>Benchmark (S&P 500)</th>
                            <th colSpan={3}>Automatic Strategy Decomposition (no leverage)</th>
                        </tr>
                        <tr>
                            <th>Long Leg Performance</th>
                            <th>Short Leg Performance</th>
                            <th>Long-Short Performance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Final-Equity", "CAGR", "Volatility", "Sharpe", "Max-Drawdown", "Average-Drawdown", "Alpha-Universe", "Beta-Universe", "Alpha-SP500",  "Beta-SP500", "Sterling", "Sortino", "Calmar", "Win-Rate", "VaR95", "CVaR95", "Skewness", "Kurtosis" ].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>{labelMap[metricTest]}</td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                                const value = report.data[key];
                                                let formatedValue = 0;
                                                if (value) {
                                                    formatedValue = toFixed(value[metricTest]);
                                                }
                                                return (
                                                    <td key={key}>{formatedValue > 0 ? "+" : ""}{padNumber(toFixed(formatedValue), 2)}{percentageFields.includes(metricTest) ? "%" : ""}</td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </Row>  
        );
    }

    if (report.error) {
        return (
            <div>
                Error fetching backtest performance!
            </div>
        );
    }

    return (
        <Row>
            <h3>Performance Details</h3>
            <Table striped bordered hover responsive>
                <thead>
                        <tr>
                            {/* {
                                ["Metrics", "Strategy", "SP100", "SP500", "LongOnly", "ShortOnly", "LongTheShort"].map((option) => (
                                    <th key={option}>{columnsMap[option as keyof typeof columnsMap]}</th>
                                ))
                            } */}
                            <th rowSpan={2}>Metrics</th>
                            <th rowSpan={2}>Strategy</th>
                            <th rowSpan={2}>Universe (S&P 100)</th>
                            <th rowSpan={2}>Benchmark (S&P 500)</th>
                            <th colSpan={3}>Automatic Strategy Decomposition (no leverage)</th>
                        </tr>
                        <tr>
                            <th>Long Leg Performance</th>
                            <th>Short Leg Performance</th>
                            <th>Long-Short Performance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Final-Equity", "CAGR", "Volatility", "Sharpe", "Max-Drawdown", "Average-Drawdown", "Alpha-Universe", "Beta-Universe", "Alpha-SP500",  "Beta-SP500", "Sterling", "Sortino", "Calmar", "Win-Rate", "VaR95", "CVaR95", "Skewness", "Kurtosis" ].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>{labelMap[metricTest]}</td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                               
                                                return (
                                                    <td key={key}></td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
            </Table>
        </Row>
    )   
}
