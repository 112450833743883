import { min } from "moment";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap"

export type FilterOperator = "<"| ">" | "=";
export type FilterType = {
    operator: FilterOperator,
    value: number
}

const Filter = ({
    value,
    operator,
    onChange,
    min,
    max,
    step,
    suffix
}: {
    value?: number,
    step?: number,
    min?: number,
    max?: number,
    suffix?: string,
    operator: FilterOperator,
    onChange: (filter: FilterType) => void
}) => {
    const operators = ["<", ">", "="] as FilterOperator[];
    // const [selectedOperator, setSelectedOperator] = useState(defaultOperator || operators[0]);
    // const [value, setValue] = useState(defaultValue);

    const changeOperator = (v: string) => {
        const operator = v as FilterOperator;
        // setSelectedOperator(operator);
        onChange({operator, value: value || 0});
    };

    const changeValue = (v: number) => {
        // setValue(value);
        onChange({operator: operator, value: v || 0});
    };

  return (
    <InputGroup>
        <Form.Select style={{
            height: "100%",
            marginLeft: "0.5rem",
        }} value={operator} onChange={(e) => changeOperator(e.target.value)}>
            {operators.map((operator, index) => (
                <option key={index} value={operator}>{operator}</option>
            ))}
        </Form.Select>
        <Form.Control type="number" value={value} onChange={(e) => changeValue(Number(e.target.value))} step={step || 1} min={min} max={max}/>
        {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
    </InputGroup>
  )
}

export default Filter