import { Table } from "react-bootstrap";
import { MdExpand } from "react-icons/md";
import styles from './Strategy.module.scss';
import { PortfolioType } from ".";
import { PortfolioConstructionType } from "../../../api/schema";

const RankingTable = ({
    portfolioType,
    slider,
    assetCount,
    ascending
}: {
    portfolioType: PortfolioConstructionType;
    slider: number;
    assetCount: number;
    ascending?: boolean;
}) => {

  return (
    <>
        {portfolioType !== "ShortOnly"  &&
            <Table>
                <thead>
                    <tr>
                        
                        <th style={{
                            width: "4rem"
                            }}>Rank</th>
                        <th>Ticker</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                {Array(slider + 2).fill(() => {}).map((v, i) => {
                    return (
                    <tr key={i} className={i < slider ? styles.buyActive : ""}>
                    <td>
                    {ascending ? i + 1 : assetCount - i}
                    </td>
                    <td style={{
                        color: "transparent",
                        textShadow: "#111 0 0 5px"
                    }}>
                    TIC
                    </td>
                    <td style={{
                        color: "transparent",
                        textShadow: "#111 0 0 5px"
                    }}>
                    Company Name
                    </td>
                </tr>
                )})}
                </tbody>
            </Table>
        }
        {portfolioType === "LongShort"  &&
            <div className={styles.separator}>
                <div>
                <MdExpand size={"2rem"}/> {assetCount - (2 * slider + 4)} more rows collapsed
                </div>
            </div>
        }
        {portfolioType !== "LongOnly"  && 
                <Table>
                    <thead>
                        <tr>
                            <th style={{
                            width: "4rem"
                            }}>
                                Rank
                            </th>
                            <th>Ticker</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(slider + 2 )
                            .fill(() => {})
                            .map((v, i) => {
                                const adjustedAscending = portfolioType !== "ShortOnly" ? ascending : !ascending;
                                return (
                                    <tr key={i} className={i < 2 ? "" : styles.sellActive}>
                                        <td>
                                            {adjustedAscending ? assetCount - (slider - (i - 1)) : slider - i + 2}
                                        </td>
                                        <td style={{
                                            color: "transparent",
                                            textShadow: "#111 0 0 5px"
                                        }}>
                                            TIC
                                        </td>
                                        <td style={{
                                            color: "transparent",
                                            textShadow: "#111 0 0 5px"
                                        }}>
                                            Company Name
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            }
    </>
  )
}

export default RankingTable