import { useState } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from "react-bootstrap"
import { recalculateModel } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { FactorModelType, PortfolioConstructionType } from "../../../api/schema";
import { useBusy } from "../../../hooks/useBusy";
import { useMessages } from "../../../hooks/useMessages";

export type SettingsFormProps = {
    prop: string;
    value: string|number|boolean;
};

const SettingsForm = ({
    onSettingsChange,
    model
}: {
    model: FactorModelType;
    onSettingsChange: (settings: SettingsFormProps) => void
}) => {
    const backtest = model.backtest || {
        portfolioConstruction: "LongShort",
        leverage: 1,
        longsShare: 0.5,
        shortsShare: 0.5,
        startCash: 10000,
        numberOfStocks: 4,
        ascending: true
    };
    const [slider, setSlider] = useState(backtest.numberOfStocks);
    const [initialCapital, setInitialCapital] = useState(backtest.startCash.toString());
    const [portfolioType, setPortfolioType] = useState<PortfolioConstructionType>(backtest.portfolioConstruction);

    const [rankingRules, setRankingRules] = useState(backtest.ascending ? "lowest" : "highest");
    const [longsShare, setLongsShare] = useState(backtest.longsShare * 100);
    const [shortsShare, setShortsShare] = useState(backtest.shortsShare * 100);
    const [leverage, setLeverage] = useState(backtest.leverage);
    const {setIsBusy} = useBusy();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {addErrorMessage, addInfoMessage} = useMessages();
    const MAX_SLIDER = 30;

    const mutation = useMutation({
        mutationFn: async () => {
            setIsBusy(true);
            addInfoMessage("Info");
            await recalculateModel(model.userId, model.modelId, model.strategyId || "",{
                startCash: Number(initialCapital),
                leverage: leverage,
                longsShare: (longsShare/100),
                shortsShare: (shortsShare/100),
                numberOfStocks: slider,
                backtestPeriod: "2020-01-01",
                portfolioConstruction: portfolioType,
                ascending: rankingRules === "lowest"
            });
            setIsBusy(false);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['models'] });
            navigate(`/${model.modelId}`);
            addInfoMessage("Portfolio updated", "Your portfolio has been successfully updated");
        },
        onError: (error: any) => {
            addErrorMessage("Error", "Your portfolio has not been updated. Please try again later");
        }
    });

    const onLongShareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLongsShare(Number(e.target.value));
        setShortsShare(100 - Number(e.target.value));
    };

    const onShortShareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShortsShare(Number(e.target.value));
        setLongsShare(100 - Number(e.target.value));
    };

    const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setSlider(value);
        onSettingsChange({
            prop: "slider",
            value: value
        });
    };

    const onInitialCapitalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInitialCapital(value);
        onSettingsChange({
            prop: "initialCapital",
            value: value
        });
    };

    const onLeverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (value < 0 || value > 5) {
            return;
        }
        setLeverage(value);
        onSettingsChange({
            prop: "leverage",
            value: value
        });
    };

    const onConstructionChange = (type: PortfolioConstructionType) => {
        setPortfolioType(type);
        if (type === "LongOnly") {
            setLongsShare(100);
            setShortsShare(0);
        }
        if (type === "ShortOnly") {
            setLongsShare(0);
            setShortsShare(100);
        }
        if (type === "LongShort") {
            setLongsShare(50);
            setShortsShare(50);
        }
        onSettingsChange({
            prop: "portfolioType",
            value: type
        });
    };


    const onRankingRuleChange = (type: string) => {
        setRankingRules(type);
        onSettingsChange({
            prop: "rankingRules",
            value: type
        });
    };

    return (
        <Form>
            <Form.Group className="mb-3" controlId="composition">
                <Form.Label>Strategy composition</Form.Label>
                <Row>
                <Col xs={1}>
                    <span>1</span>
                </Col>
                <Col>
                    <Form.Range min={1} max={MAX_SLIDER} value={slider} onChange={onSliderChange}/>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>{slider}</div>
                </Col>
                <Col xs={1}>
                    <span>{MAX_SLIDER}</span>
                </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="backtest-period">
                <Form.Label>Ranking Rules</Form.Label>
                <Row>
                    <Col>
                        <ButtonGroup aria-label="Basic example" className="w-100">
                            <Button variant={rankingRules === "highest" ? "primary" : "secondary"} onClick={() => onRankingRuleChange("highest")}>Highest Score</Button>
                            <Button variant={rankingRules === "lowest" ? "primary" : "secondary"} onClick={() => onRankingRuleChange("lowest")}>Lowest Score</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="backtest-period">
                <Form.Label>Portfolio construction</Form.Label>
                <Row>
                    <Col>
                        <ButtonGroup aria-label="Basic example" className="w-100">
                            <Button variant={portfolioType === "LongShort" ? "primary" : "secondary"} onClick={() => onConstructionChange("LongShort")}>Long-Short</Button>
                            <Button variant={portfolioType === "LongOnly" ? "primary" : "secondary"} onClick={() => onConstructionChange("LongOnly")}>Long</Button>
                            <Button variant={portfolioType === "ShortOnly" ? "primary" : "secondary"} onClick={() => onConstructionChange("ShortOnly")}>Short</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="capital">
                <Form.Label>Initial capital</Form.Label>
                <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control type="number"  value={initialCapital} onChange={onInitialCapitalChange} />
                </InputGroup>
                {/* <Form.Control aria-label="Amount (to the nearest dollar)" /> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="capital-distribution">
                <Row>
                <Col>
                    <Form.Label>Longs share</Form.Label>
                    <InputGroup>
                        <Form.Control disabled={portfolioType !== "LongShort"} type="number" min={0} max={100} value={longsShare} onChange={onLongShareChange}/>
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <Form.Label>Shorts share</Form.Label>
                    <InputGroup>
                        <Form.Control disabled={portfolioType !== "LongShort"} type="number" min={0} max={100} value={shortsShare} onChange={onShortShareChange} />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                </Col>
                </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="leverage">
                <Form.Label>Leverage</Form.Label>
                <Form.Control type="number" step={0.1} max={5} min={0} value={leverage} onChange={onLeverageChange} />
            </Form.Group>

            <Button variant="primary" type="submit" onClick={(e) => {
                e.preventDefault();
                mutation.mutate();
            } }>
                Update strategy
            </Button>
        </Form>
  )
}

export default SettingsForm