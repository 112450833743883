import ReactECharts from 'echarts-for-react'; 
import { toFixed } from "../../../utils/utils";
import { fetchScore } from '../../../api/api';
import { useCallback } from 'react';
import { Button } from 'react-bootstrap';

const ReportPieChart = ({
  center,
  title,
  shares = {},
  gain = {},
  type,
  revert
}: {
  center?: number | null;
  title: string;
  shares?: any;
  gain?: any;
  type: "Equity" | "Percent";
  revert?: boolean;
}) => {
  const sum = Object.keys(shares).reduce((acc, ticker) => acc + shares[ticker], 0);
  const subtext = type === "Equity" ? `Total ${toFixed(sum)} USD`: `${toFixed(sum * 100)}% of portfolio`; 


  const options = {
    title: {
      text: title,
      subtext: subtext,
      left: 'left'
    },
    tooltip: {
      trigger: 'item',
      formatter: (params: any, ticket: string, callback: () => void) => {
        const companyGain = toFixed(gain[params.data.name] * 100 || 0, 2);
        const isPositive = companyGain > 0;
        const arrow = isPositive ? "▲" : "▼";
        const gainColor = isPositive ? "green" : "red";
        const mainPart = type === "Equity" ? revert ? `${params.marker}${params.data.name}: -${params.data.value} USD` : `${params.marker}${params.data.name}: ${params.data.value} USD` : `Portion of portfolio: </br>${params.marker}${params.data.name}: ${params.data.value} %`;
        const gainPart = companyGain !== 0 ? `<span style=\"display:inline-block;margin-right:4px;color:${gainColor} \">${arrow}</span> ${isPositive ? "+" : "-"} ${Math.abs(companyGain)}%` : "";


        return `${mainPart}<br/>${gainPart}`;
      }
    },
    legend: {
      bottom: 0,
    },
    series: [
      {
        name: 'Portion of Portfolio',
        type: 'pie',
        radius: ['40%', '70%'],
        data: Object.keys(shares).map((ticker) => ({value: type === "Equity" ?  revert ? (toFixed(shares[ticker]) * -1) : toFixed(shares[ticker]) : toFixed((shares[ticker] * 100 )), name: ticker})),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          color: '#000',
          fontSize: '20',
          position: 'center',
          formatter: () => {
            return center ? `${(toFixed(center * 100))}%` : "";
          },
        }
      }
    ]
  };
  return (
    <div>
      {/* <Button onClick={() => {if(onDownloadPress) onDownloadPress()}} >Fetch Score</Button> */}
      <ReactECharts option={options} notMerge={true} />
    </div>
  )
}

export default ReportPieChart;