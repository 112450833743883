import { z } from 'zod';

export const backtestSchema = z.object({
    "backtest-id": z.string(),
    "backtest-period": z.string(),
    "n-simulations": z.number().min(0).max(100, "Max 100 simulations"),
    "number-of-stocks": z.number().min(0).max(100),
    "simul-random-backtest": z.boolean(),
    "exposure": z.number().min(0, "Zero").max(2),
    "leverage": z.number()
});
export type BacktestType = z.infer<typeof backtestSchema>;  

export const backtestChartItemSchema = z.object({
    date: z.string(),
    LongOnly: z.number(),
    ShortOnly: z.number(),
    LongTheShort: z.number(),
    LongShort: z.number(),
    Universe: z.number(),
    SP500: z.number(),
    SP100: z.number(),
    Strategy: z.number(),
    FamaSMB_5f: z.number().optional(),
    FamaRMV: z.number().optional(),
    FamaCMA: z.number().optional(),
    FamaHML: z.number().optional(),
    "FamaMkt-RF": z.number().optional(),
    FamaSMB_3f: z.number().optional()

});
export const backtestChartItemsSchema = z.array(backtestChartItemSchema);
export const backtestChartApiResponse = z.object({
        Date: z.record(z.string(), z.string()),
        LongOnly: z.record(z.string(), z.number()),
        ShortOnly: z.record(z.string(), z.number()),
        LongTheShort: z.record(z.string(), z.number()),
        LongShort: z.record(z.string(), z.number()),
        Universe: z.record(z.string(), z.number()),
        SP100: z.record(z.string(), z.number()),
        SP500: z.record(z.string(), z.number()),
        Strategy: z.record(z.string(), z.number().optional().nullable()),
        FamaSMB_5f: z.record(z.string(), z.number().optional().nullable()),
        FamaRMV: z.record(z.string(), z.number().optional().nullable()),
        FamaCMA: z.record(z.string(), z.number().optional().nullable()),
        FamaHML: z.record(z.string(), z.number().optional().nullable()),
        "FamaMkt-RF": z.record(z.string(), z.number().optional().nullable()),
        FamaSMB_3f: z.record(z.string(), z.number().optional().nullable())
});

export type BacktestChartItem = z.infer<typeof backtestChartItemSchema>;
export type BacktestChartKeys = Exclude<keyof BacktestChartItem, "date">;
export type BacktestChartApiResponse = z.infer<typeof backtestChartApiResponse>;
export type MetricsEnum = z.infer<typeof metricsSchema>;

export const backtestReportItemSchema = z.object({
    "Final-Equity": z.number(),
    "Volatility": z.number(),
    "Sharpe": z.number(),
    "Alpha-Universe": z.number(),
    "Alpha-SP500": z.number(),
    "Beta-Universe": z.number(),
    "Beta-SP500": z.number(),
    "CAGR": z.number(),
    "Max-Drawdown": z.number(),
    "Average-Drawdown": z.number(),
    "Win-Rate": z.number(),
    "Skewness": z.number(),
    "Kurtosis": z.number(),
    "VaR95": z.number(),
    "CVaR95": z.number(),
    "Calmar": z.number(),
    "Sterling": z.number(),
    "Sortino": z.number()
});


export const metricsSchema = z.enum(["LongOnly", "LongTheShort", "LongShort", "Universe", "SP100", "SP500", "ShortOnly", "Strategy", "FamaSMB_5f", "FamaRMV", "FamaCMA", "FamaHML", "FamaMkt-RF", "FamaSMB_3f"]);


export const backtestReportSchema = z.record(metricsSchema, backtestReportItemSchema);
export const newModelSchema = z.object({
    "rollingWindow": z.number().optional(),
    "startCash": z.number().optional(),
    "backtestPeriod": z.string().optional(),
    "numberOfStocks": z.number().optional(),
    "leverage": z.number().optional(),
    "longsShare": z.number().optional(),
    "shortsShare": z.number().optional(),
    "portfolioConstruction": z.enum(["LongOnly", "LongShort", "ShortOnly"]).optional(),
    "ascending": z.boolean().optional()
});

export const createBacktestFormSchema = z.object({
    "leverage": z.number(),
    "longs-share": z.number(),
    "shorts-share": z.number(),
    "start-cash": z.number(),
    "number-of-stocks": z.number(),
    "backtest-period": z.string(),
    "rolling-window": z.number().optional(),
    "frequency": z.string().optional()
});

export type CreateBacktestForm = z.infer<typeof createBacktestFormSchema>;

export type NewModel = z.infer<typeof newModelSchema>;
export type BacktestReportItem = z.infer<typeof backtestReportItemSchema>;
export type BacktestReportResponse = z.infer<typeof backtestReportSchema>;

export const createBacktestSchema = z.object({
    "user-id": z.string(),
    "model-id": z.string(),
    "features": z.record(z.string(), z.number()),
    "strategy-id": z.string(),
    "pool": z.string(),
    "frequency": z.string(),
    "backtest-period": z.string(),
    "number-of-stocks": z.number(),
    "long-only": z.boolean(),
    "simul-random-backtest": z.boolean(),
    "n-simulations": z.number(),
    "exposure": z.number(),
    "leverage": z.number(),
    "status": z.enum(["success", "failure"])
});

const createBacktestResponseSchema = z.object({
    "results": createBacktestSchema
});
export type CreateBacktestType = z.infer<typeof createBacktestResponseSchema>;
export type CreateBacktestResponseType = z.infer<typeof createBacktestResponseSchema>;

export const strategySchema = z.object({
    "poolName": z.string(),
    "PK": z.string(),
    "instanceCountTraining": z.number(),
    "maxRuntime": z.number(),
    "validationPeriodEnd": z.string(),
    "userId": z.string(),
    "staticJyperparameters": z.object({
        "predictor_type": z.string()
    }).optional(),
    "image": z.string(),
    "algorithm": z.string().optional(),
    "instanceTypeProcessing": z.string(),
    "instanceCountProcessing": z.number(),
    "instanceTypeTraining": z.string(),
    "frequency": z.string(),
    "latestReportId": z.string().optional(),
    "trainPeriodEnd": z.string(),
    "trainPeriodStart": z.string(),
    "hyperparameters": z.object({}).optional(),
    "strategyName": z.string(),
    "reports": z.array(z.object({
        "leverage": z.string(),
        "path": z.string().optional(),
        "exposure": z.string().optional(),
        "actualOrdersPath": z.string().optional(),
        "reportId": z.string().optional(),
        "calculated": z.string().optional(),
    })).optional()
});
export const strategyListSchema = z.array(strategySchema);
export type StrategyObject = z.infer<typeof strategySchema>;


export const orderSchema = z.object({
    "symbol": z.string(),
    "score": z.number(),
    "current_value": z.number(),
    "current_qty": z.number(),
    "order_value": z.number(),
    "order_qty": z.number(),
    "final_value": z.number(),
    "final_qty": z.number(),
    "current_asset_price": z.number(),
    "liquidate": z.boolean()
});
export type OrderObject = z.infer<typeof orderSchema>;

export const reportSchema = z.object({
    "Ticker": z.string(),
    "Value": z.number()
});

export type ReportObject = z.infer<typeof reportSchema>;

export const rebalanceSchema = z.object({
    "balance": z.number(),
    "message": z.string(),
    "value_per_long": z.number(),
    "value_per_short": z.number(),
    "leverage": z.number(),
    "exposure": z.number(),
    "nr_longs": z.number(),
    "nr_short": z.number(),
    "non_tradable": z.array(z.string()),
    "nr_untraded_shorts": z.number(),
    "too_expensive_shorts": z.array(z.string()),
    orders: z.array(orderSchema)
});

export type RebalanceObject = z.infer<typeof rebalanceSchema>;

export const rebalanceAPIResponseSchema = z.object({
    "results": rebalanceSchema.or(z.array(reportSchema))
});
export type RebalanceAPIResponseType = z.infer<typeof rebalanceAPIResponseSchema>;

export const factorSchema = z.object({
    "Feature": z.string(),
    "Bucket": z.string(),
    "Description": z.string(),
});
export type FactorType = z.infer<typeof factorSchema>;
export const factorRankingItemSchema = z.object({
    "Factor": z.string(),
    "T-Stat": z.number(),
    "P-Value": z.number(),
    "Direction": z.number(),
    "annualized_return": z.number().nullable().optional(),
    "Mean": z.number().nullable().optional(),
    "Stdev": z.number().nullable().optional(),
    "Stderr": z.number().nullable().optional(),
    "1Y_Return": z.number().nullable().optional(),
    "3Y_Return": z.number().nullable().optional(),
    "5Y_Return": z.number().nullable().optional(),
    "10Y_Return": z.number().nullable().optional(),
    "FM_return": z.number().nullable().optional()
});
export const factorRankingSchema = z.array(factorRankingItemSchema);
export type FactorRankingItem = z.infer<typeof factorRankingItemSchema>;

export const factorMomentumItemSchema = z.object({
    "Date": z.number(),
    "Log_Return_Q1": z.number(),
    "Log_Return_Q2": z.number(),
    "Log_Return_Q3": z.number(),
    "Log_Return_Q4": z.number(),
    "Log_Return_Q5": z.number(),
    "Log_Return_Q5-Q1": z.number(),
});
   
export const factorMomentumSchema = z.object({
  "results":  z.array(factorMomentumItemSchema)
});

export type FactorMomentumResponse = z.infer<typeof factorMomentumSchema>;
export type FactorMomentumItem = z.infer<typeof factorMomentumItemSchema>;

export const factorCumulativeReturnItemSchema = z.record(
    z.string(), z.record(
        z.string(), z.number()
    )
);

export const factorCumulativeReturnSchema = z.object({
    "results": factorCumulativeReturnItemSchema
});

export type FactorCumulativeReturnItem = z.infer<typeof factorCumulativeReturnItemSchema>;
export type FactorCumulativeReturnResponse = z.infer<typeof factorCumulativeReturnSchema>;

export const returnsSchema = z.object({
    "LongOnly": z.number(),
    "PickedLongs": z.string(),
    "PickedShorts": z.string(),
    "ShortOnly": z.number().nullable(),
    "LongShort": z.number().nullable(),
    "Strategy": z.number().nullable(),
    "Universe": z.number(),
    "LongLeg": z.number().nullable().optional(),
    "ShortLeg": z.number().nullable().optional(),
    "LongPortfolioShares": z.record(z.string(), z.number()),
    "ShortPortfolioShares": z.record(z.string(), z.number()),
    "Longs": z.record(z.string(), z.number()),
    "Shorts": z.record(z.string(), z.number()),
    "LongPortfolioUSD": z.record(z.string(), z.number()),
    "ShortPortfolioUSD": z.record(z.string(), z.number()),
    "Updated": z.string().optional(),
    "Rebalance_Day": z.string().optional()
});

export const rebalanceReturnsSchema = z.record(z.string(), returnsSchema);
export const rebalanceReturnsResponseSchema = z.object({
    "results": rebalanceReturnsSchema
});

export type RebalanceReturnsType = z.infer<typeof rebalanceReturnsSchema>;
export type RebalanceReturnsTypes = "LongOnly" | "ShortOnly" | "LongShort" | "Strategy";
export type ReturnType = z.infer<typeof returnsSchema>;
export type RebalanceReturnsResponseType = z.infer<typeof rebalanceReturnsResponseSchema>;


export const rollingStatsSchema = z.object({
    "strategy": z.string(),
    "max_percent": z.number(),
    "min_percent": z.number(),
    "fail_rate": z.number()
});
export const rollingStatsListSchema = z.array(rollingStatsSchema);

export const rollingStatsResponseSchema = z.object({
    "results": z.array(rollingStatsSchema)
});

export type RollingStatsType = z.infer<typeof rollingStatsSchema>;
export type RollingStatsListType = z.infer<typeof rollingStatsListSchema>;
export type RollingStatsResponseType = z.infer<typeof rollingStatsResponseSchema>;

export const factorMeanQuantileSchema = z.object({
    "Log_Return_Q1": z.number(),
    "Log_Return_Q2": z.number(),
    "Log_Return_Q3": z.number(),
    "Log_Return_Q4": z.number(),
    "Log_Return_Q5": z.number(),
    "Log_Return_Q5-Q1": z.number()

});
export const factorMeanQuantileResponseSchema = z.object({
    "result": factorMeanQuantileSchema
})

export type FactorMeanQuantileType = z.infer<typeof factorMeanQuantileSchema>;
export type FactorMeanQuantileResponseType = z.infer<typeof factorMeanQuantileResponseSchema>;
export const drawdownSchema = z.object({
    "Strategy": z.record(z.string(), z.number()),
    "SP100": z.record(z.string(), z.number()),
    "SP500": z.record(z.string(), z.number()),
    "LongOnly": z.record(z.string(), z.number()),
    "ShortOnly": z.record(z.string(), z.number()),
    "LongTheShort": z.record(z.string(), z.number())
});

export type DrawdownType = z.infer<typeof drawdownSchema>;
export type DrawdownTypes = keyof DrawdownType;

export const performanceMetricsSchema = z.object({
    "1 years": z.number(),
    "2 years": z.number(),
    "3 years": z.number()
});
export const performanceMetricsResponseSchema = z.object({
    "Alpha-Universe_LongOnly": performanceMetricsSchema,
    "Alpha-Universe_LongShort": performanceMetricsSchema,
    "Alpha-Universe_LongTheShort": performanceMetricsSchema,
    "Alpha-Universe_Universe": performanceMetricsSchema,
    "Beta-Universe_LongOnly": performanceMetricsSchema,
    "Beta-Universe_LongShort": performanceMetricsSchema,
    "Beta-Universe_LongTheShort": performanceMetricsSchema,
    "Beta-Universe_Universe": performanceMetricsSchema,
    "Sharpe_LongOnly": performanceMetricsSchema,
    "Sharpe_LongShort": performanceMetricsSchema,
    "Sharpe_LongTheShort": performanceMetricsSchema,
    "Sharpe_Universe": performanceMetricsSchema
});

export type PerformanceMetricsType = z.infer<typeof performanceMetricsSchema>;
export type PerformanceMetricsResponseType = z.infer<typeof performanceMetricsResponseSchema>;


export const performanceTimelineSchema = z.record(z.string(), z.number().nullable());
export const performanceTimelineResponseSchema = z.object({
    "Beta_universe_LongOnly": performanceTimelineSchema,
    "Alpha_universe_LongOnly": performanceTimelineSchema,
    "Beta_universe_LongShort": performanceTimelineSchema,
    "Alpha_universe_LongShort": performanceTimelineSchema,
    "Beta_universe_LongTheShort": performanceTimelineSchema,
    "Alpha_universe_LongTheShort": performanceTimelineSchema,
    "Beta_universe_Universe": performanceTimelineSchema,
    "Alpha_universe_Universe": performanceTimelineSchema,
    "Sharpe_LongOnly": performanceTimelineSchema,
    "Sharpe_LongShort": performanceTimelineSchema,
    "Sharpe_LongTheShort": performanceTimelineSchema,
    "Sharpe_Universe": performanceTimelineSchema

});

export type PerformanceTimelineType = z.infer<typeof performanceTimelineSchema>;
export type PerformanceTimelineResponseType = z.infer<typeof performanceTimelineResponseSchema>;


export const modelAccuracySchema = z.object({
    "accuracy_normalized": z.object({
        "MAE": z.number(),
        "R2": z.number()
    }),
    "accuracy_normalized_over_time": z.object({
        "MAE": z.record(z.string(), z.number()),
        "R2": z.record(z.string(), z.number())
    }),
    "normalized_log_return": z.object({
        "max": z.record(z.string(), z.number()),
        "mean": z.record(z.string(), z.number()),
        "min": z.record(z.string(), z.number()),
        "std": z.record(z.string(), z.number()),
        "stderr": z.record(z.string(), z.number()),
    }),
    "normalized_score": z.object({
        "max": z.record(z.string(), z.number()),
        "mean": z.record(z.string(), z.number()),
        "min": z.record(z.string(), z.number()),
        "std": z.record(z.string(), z.number()),
        "stderr": z.record(z.string(), z.number()),
    })
});

export type ModelAccuracyType = z.infer<typeof modelAccuracySchema>;

export const factorListSchema = strategySchema.extend({
    factors: z.array(factorRankingItemSchema)
});
export const factorListResponseSchema = z.object({
    result: z.array(factorListSchema)
});

export const factorDetailsSchema = z.object({
    "Feature": z.string(),
    "Description": z.string().nullable(),
    "Bucket": z.string()
});
export const factorDetailsResponseSchema = z.array(factorDetailsSchema);

export const factorStrategy = factorRankingItemSchema.extend(strategySchema.shape);

export type FactorDetailsType = z.infer<typeof factorDetailsSchema>;
export type FactorDetailsResponseType = z.infer<typeof factorDetailsResponseSchema>;
export type FactorListType = z.infer<typeof factorListSchema>;
export type FactorListResponseType = z.infer<typeof factorListResponseSchema>;
export type FactorStrategy = z.infer<typeof factorStrategy>;

export const portfolioConstruction = z.enum(["LongOnly", "LongShort", "ShortOnly"]);
export const modelBacktestSchema = z.object({
    "backtestPeriod": z.string(),
    "leverage": z.number(),
    "longsShare": z.number(),
    "nSimulations": z.number(),
    "numberOfStocks": z.number(),
    "rollingWindow": z.number(),
    "shortsShare": z.number(),
    "startCash": z.number(),
    "portfolioConstruction": portfolioConstruction,
    "ascending": z.boolean(),
    "benchmark": z.string().nullable().optional()
});
export const factorModelSchema = z.object({
    userId: z.string(),
    modelId: z.string(),
    name: z.string().optional(),
    factor: z.record(z.string(), z.number()).optional(),
    strategyId: z.string().optional(),
    backtest: modelBacktestSchema.optional(),
});

export const factorModelResponseSchema = z.object({
    results: z.array(factorModelSchema)
});

export type PortfolioConstructionType = z.infer<typeof portfolioConstruction>;
export type FactorModelType = z.infer<typeof factorModelSchema>;
export type FactorModelResponseType = z.infer<typeof factorModelResponseSchema>;

export const longShortCumulativeSchema = z.object({
    "Date": z.number(),
    "Factor": z.number(),
    "Universe": z.number(),
});

export const longShortCumulativeResponseSchema = z.object({
    results: z.array(longShortCumulativeSchema)
});

export type LongShortCumulativeType = z.infer<typeof longShortCumulativeSchema>;
export type LongShortCumulativeResponseType = z.infer<typeof longShortCumulativeResponseSchema>;

export const universeSchema = z.object({
    SK: z.string(),
    assetsCount: z.number(),
    bottomAssets: z.number(),
    longShortQuantiles: z.object({
        Q1: z.string(),
        Q5: z.string()
    }),
    numberOfQuantiles: z.number(),
    topAssets: z.number(),
    universe: z.string(),
});
export const universesSchema = z.array(universeSchema);
export type UniverseType = z.infer<typeof universeSchema>;

export const factorPerformanceSchema = z.array(
    z.object({
        "Value": z.number().optional(),
        "Factor": z.string(),
        "Date": z.number(),
        "Class": z.string(),
        "Strategy": z.string()
    })
);

export const performanceReducedSchema = z.record(
    z.string(), z.array(z.object({
        "performance": z.number(),
        "factor": z.string(),
        "date": z.number()
    })
));
export const factorResponseSchema = z.object({
    results: factorPerformanceSchema
});
export type FactorPerformanceType = z.infer<typeof factorPerformanceSchema>;
export type FactorPerformanceReducedType = z.infer<typeof performanceReducedSchema>;
export type FactorPerformanceResponseType = z.infer<typeof factorResponseSchema>;

export const benchmarkSchema = z.object({
    "name": z.string(),
    "universe": z.string(),
    "type": z.enum(["benchmarks", "FamaFrench"]),
});
export const benchmarkResponseSchema = z.object({
    results: z.record(z.string(), benchmarkSchema)
});

export type BenchmarkType = z.infer<typeof benchmarkSchema>;
export type BenchmarkResponseType = z.infer<typeof benchmarkResponseSchema>;

export const f3FamaFrenchSchema = z.record(
    z.string(), z.object({
        hmlCumulative: z.number(),
        smbCumulative: z.number(),
        mktRfCumulative: z.number()
    })
);
export const f3FamaFrenchResponseSchema = z.object({
    results: f3FamaFrenchSchema
});
export type F3FamaFrenchType = z.infer<typeof f3FamaFrenchSchema>;
export type F3FamaFrenchResponseType = z.infer<typeof f3FamaFrenchResponseSchema>;

export const f5FamaFrenchSchema = z.record(
    z.string(), z.object({
        hmlCumulative: z.number(),
        smbCumulative: z.number(),
        rmwCumulative: z.number(),
        cmaCumulative: z.number(),
        mktRfCumulative: z.number()
    })
);
export const f5FamaFrenchResponseSchema = z.object({
    results: f5FamaFrenchSchema
});
export type F5FamaFrenchType = z.infer<typeof f5FamaFrenchSchema>;
export type F5FamaFrenchResponseType = z.infer<typeof f5FamaFrenchResponseSchema>;


export const factorMimickingZoomSchema = z.object({
    userId: z.string(),
    strategyId: z.string(),
    pool: z.string(),
    frequency: z.string(),
    from: z.string(),
    to: z.string()
});

export const factorMimickingZoomResponseSchema = z.object({
    strategyId: z.string(),
    userId: z.string()
});

export type FactorMimickingZoomType = z.infer<typeof factorMimickingZoomSchema>;
export type FactorMimickingZoomResponseType = z.infer<typeof factorMimickingZoomResponseSchema>;

